/* eslint-disable class-methods-use-this */

import { extend } from 'lodash'
import { Model } from 'vue-mc'
import Vue from 'vue'
import globalApi from '@/request/globalApi/globalApi' // our axios instance

/**
 * BaseModel
 * A base model class using the application's default axios settings.
 * Note: Always extend from this class, not the vue-mc Model.
 */
class BaseModel extends Model {
  options() {
    return {
      validateOnChange: true,
    }
  }

  $set(...arg) {
    Vue.set(...arg)
  }

  setError(err) {
    if (typeof err === 'string') {
      Vue.set(this._errors, err, true)
    }
  }

  async checkArgs(...args) {
    args.forEach(arg => {
      if (['null', 'undefined'].includes(JSON.stringify(arg))) {
        this.setError('notFound')
        throw new Error(`Type error: ${arg} is not accepted by route API`)
      }
    })
  }

  catchError(err) {
    if (err.response && err.response.status === 404) {
      this.setError('notFound')
    }
  }

  /**
   * Parses every request made by any Model in the system.
   * @param   {object}  config  Config options for any restful http call.
   * @returns {Request}         A new `Request` using the given configuration and our default axios settings.
   */
  createRequest(config) {
    // globalApi.defaults is our pre-made axios instance, we extend the given config with its default settings.
    config = extend(config, globalApi.defaults)
    return super.createRequest(config)
  }
}

export default BaseModel
